export default {
  required: v => !!v || "Este campo es requerido",

  objectRequired: v => {
    if (typeof v === "object") {
      if (Object.keys(v).length > 0) {
        return true;
      } else {
        return "Este campo es requerido";
      }
    } else {
      return "Este campo es requerido";
    }
  },

  minLength: function (minLength) {
    return v =>
      (v && v.length >= minLength) ||
      "Debe contener al menos " + minLength + " caracteres";
  },

  maxLength: function (maxLength) {
    return v =>
      (v && v.length <= maxLength) ||
      "Debe contener un máximo de " + maxLength + " caracteres";
  },

  exactlyLength: function (exactlyLength) {
    return v =>
      (v && v.length === exactlyLength) ||
      "Debe contener exactamente " + exactlyLength + " caracteres";
  },

  email: v => /.+@.+\..+/.test(v) || "El correo electrónico no es válido",

  decimal: v =>
    /^[0-9]+(\.[0-9][0-9]?)?$/.test(v) || "La cantidad ingresada no es válida",

  minValue: function (minValue) {
    return v =>
      (v && v >= minValue) || "El valor mínimo aceptado es " + minValue;
  },
  maxValue: function (maxValue) {
    return v =>
      (v && v <= maxValue) || "El valor máximo aceptado es " + maxValue;
  },
  // 
  nit: v => /(^([0-9]{3,9})(((k|K|\d){1})?)$)/.test(v) || "El NIT no es válido"
};