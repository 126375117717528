<template>
  <v-container>
    <head-title
      text="Ingreso de Factura"
      :to="{ name: 'accounting-register-buy-home' }"
    ></head-title>
    <v-form ref="form" v-model="valid" :lazy-validation="false">
      <v-row justify="center">
        <!-- TODO: make component -->
        <v-col cols="12" md="4">
          <provider-field
            v-model="providerId"
            :required="true"
          ></provider-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Serie"
            v-model="serie"
            :rules="onlyRequired"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Número"
            v-model="number"
            :rules="onlyRequired"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <date-field label="Fecha" v-model="date"></date-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Descripción"
            v-model="description"
            :rules="onlyRequired"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Valor"
            v-model="amount"
            @input="sumBnS()"
            :rules="onlyRequired"
          ></v-text-field>
        </v-col>
      </v-row>
      <hr />
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            label="Bienes"
            v-model="totalBienes"
            :disabled="!validAmount"
            :readonly="enableIdp || !enableBienes"
            :append-icon="
              enableBienes
                ? 'mdi-check-box-outline'
                : 'mdi-checkbox-blank-outline'
            "
            @input="sumBnS()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Servicios"
            :disabled="!validAmount || enableIdp"
            :readonly="!enableServicios"
            v-model="totalServicios"
            @input="sumBnS()"
            :append-icon="
              enableServicios
                ? 'mdi-check-box-outline'
                : 'mdi-checkbox-blank-outline'
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="idp"
            label="IDP"
            :disabled="!validAmount && !enableIdp"
            append-outer-icon="mdi-calculator"
            @click:append-outer="openIdpDialog()"
            :append-icon="
              enableIdp ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'
            "
            @click:append="enableIdp = !enableIdp"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="isr"
            label="ISR Retenciones"
            :disabled="!validAmount && !enableIsr"
            :append-icon="
              enableIsr ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'
            "
            @click:append="enableIsr = !enableIsr"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Exento"
            :disabled="!validAmount"
            v-model="exento"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="iva"
            label="IVA"
            :disabled="!validAmount || !enableIva"
            :readonly="!enableIva"
            :append-icon="
              enableIva ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'
            "
            @click:append="enableIva = !enableIva"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <accounting-item :resetForm="reset" />
    <!-- dialog -->
    <v-dialog v-model="idpDialog" max-width="500px">
      <v-card>
        <v-card-title> Calcular IDP </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4"><v-subheader>Super</v-subheader></v-col>
            <v-col cols="4">
              <v-text-field label="Galones" v-model="gSuper"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                disabled
                label="Impuesto"
                v-model="iSuper"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"><v-subheader>Regular</v-subheader></v-col>
            <v-col cols="4">
              <v-text-field label="Galones" v-model="gRegular"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                disabled
                label="Impuesto"
                v-model="iRegular"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"><v-subheader>Diesel</v-subheader></v-col>
            <v-col cols="4">
              <v-text-field label="Galones" v-model="gDiesel"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                disabled
                label="Impuesto"
                v-model="iDiesel"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"><v-subheader>Total</v-subheader></v-col>
            <v-col cols="8">
              <v-text-field disabled v-model="totalIdp"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="closeDialog()"> Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              idp = totalIdp;
              closeDialog();
            "
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="end">
      <v-col cols="12" md="2">
        <v-btn block color="warning" @click="clearForm()">Borrar</v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn block color="success" :disabled="!valid"> Guardar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import rules from "@/utils/form.rules";
export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    AccountingItem: () => import("@/components/accounting/AccountingItem"),
    ProviderField: () => import("@/components/fields/ProviderField"),
    DateField: () => import("@/components/fields/DateField.vue"),
  },
  data: () => ({
    enableIva: true,
    enableIdp: false,
    enableIsr: false,
    enableBienes: true,
    enableServicios: false,
    validAmount: false,
    idpDialog: false,
    gSuper: "",
    gRegular: "",
    gDiesel: "",
    iSuper: 0,
    iRegular: 0,
    iDiesel: 0,
    pSuper: 4.47,
    pRegular: 4.6,
    pDiesel: 1.3,
    totalIdp: 0,
    totalBienes: "",
    totalServicios: "",
    totalBnS: 0,
    providerId: 0,
    valid: true,
    amount: "",
    iva: "",
    idp: "",
    isr: "",
    exento: "",
    serie: "",
    number: "",
    date: "",
    description: "",
    reset: false,
    //rules
    onlyRequired: [rules.required],

    // decimalRules: [rules.decimal],
  }),
  methods: {
    getNumber(value) {
      return Number(value) || Number(0);
    },
    calculateIva() {
      if (this.enableIva && this.getNumber(this.amount) > 0) {
        this.iva = ((this.totalBnS / 1.12) * 0.12).toFixed(2);
      }
    },
    calculateBienes() {
      if (this.getNumber(this.amount) > 0) {
        if (this.enableIdp) {
          this.totalBienes =
            this.getNumber(this.amount) - this.getNumber(this.idp);
        } else {
          this.totalBienes = this.amount;
        }
      }
    },
    calculaIsr() {
      if (this.enableIsr) {
        this.isr = ((this.getNumber(this.amount) / 1.12) * 0.05).toFixed(2);
      }
    },
    sumIdp() {
      this.totalIdp = 0;
      this.totalIdp = (
        Number(this.iRegular) +
        Number(this.iSuper) +
        Number(this.iDiesel)
      ).toFixed(2);
    },
    sumBnS() {
      this.calculateBienes();
      this.totalBnS =
        this.getNumber(this.totalBienes) + this.getNumber(this.totalServicios);
      this.calculateIva();
      this.calculaIsr();
    },
    closeDialog() {
      this.gSuper = "";
      this.gRegular = "";
      this.gDiesel = "";
      this.iSuper = 0;
      this.iRegular = 0;
      this.iDiesel = 0;
      this.totalIdp = "";
      this.idpDialog = false;
    },
    openIdpDialog() {
      if (this.enableIdp === true) {
        this.idpDialog = true;
      }
    },
    clearForm() {
      this.enableIva = true;
      this.enableIdp = false;
      this.enableIsr = false;
      this.enableBienes = true;
      this.enableServicios = false;
      this.validAmount = false;
      this.idpDialog = false;
      this.gSuper = "";
      this.gRegular = "";
      this.gDiesel = "";
      this.iSuper = 0;
      this.iRegular = 0;
      this.iDiesel = 0;
      this.pSuper = 4.47;
      this.pRegular = 4.6;
      this.pDiesel = 1.3;
      this.totalIdp = 0;
      this.totalBienes = "";
      this.totalServicios = "";
      this.totalBnS = 0;
      this.providerId = 0;
      this.valid = true;
      this.amount = "";
      this.iva = "";
      this.idp = "";
      this.isr = "";
      this.exento = "";
      this.reset = true;
      this.$refs.form.reset();
    },
  },
  watch: {
    enableIva() {
      this.iva = "";
      this.sumBnS();
    },
    enableIdp() {
      this.idp = "";
    },
    enableIsr() {
      this.calculaIsr();
    },
    idp() {
      this.sumBnS();
    },
    amount(newValue) {
      if (this.getNumber(newValue) > 0) {
        this.validAmount = true;
        this.sumBnS();
      } else {
        this.validAmount = false;
        this.iva = "";
        this.idp = "";
        this.isr = "";
        this.exento = "";
        this.totalBienes = "";
        this.totalServicios = "";
        this.enableIdp = false;
        this.enableIsr = false;
      }
    },
    gSuper(newValue) {
      if (newValue.length > 0) {
        this.iSuper = ((Number(newValue) || 0) * 4.7).toFixed(3);
        this.sumIdp();
      }
    },
    gRegular(newValue) {
      if (newValue.length > 0) {
        this.iRegular = ((Number(newValue) || 0) * 4.6).toFixed(3);
        this.sumIdp();
      }
    },
    gDiesel(newValue) {
      if (newValue.length > 0) {
        this.iDiesel = ((Number(newValue) || 0) * 1.3).toFixed(3);
        this.sumIdp();
      }
    },
    providerId(newValue) {
      if (newValue != null) {
        this.enableIva = !newValue.disable_iva;
        this.enableIdp = newValue.idp;
        this.enableIsr = newValue.isr;
      }
    },
  },
};
</script>
